/** true means its live */
export const isProduction =
  window.location.hostname === 'app.rovitracker.cloud' ? true : false

export const findEnv = () => {
  switch (window.location.hostname) {
    case 'app.rovitracker.cloud':
      return 'production'
    case 'https://app.staging.rovitracker.cloud':
      return 'staging'
    default:
      return 'local'
  }
}
