import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import Amplify from 'aws-amplify'
import awsExports from './aws-exports'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { muiTheme } from './components/ux/roviTheme'
import CssBaseline from '@mui/material/CssBaseline'
import { findEnv, isProduction } from './utility/hostCheck'

Amplify.configure(awsExports)

const { REACT_APP_SENTRY_DSN } = process.env;

window.location.hostname !== 'localhost' &&
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    environment: findEnv(),
    tracesSampleRate: isProduction ? 0.25 : 1.0,
    release: 'rovitracker-web@' + process.env.REACT_APP_VERSION,
  })

ReactDOM.render(
  <ThemeProvider theme={createTheme(muiTheme)}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://github.com/facebook/create-react-app/blob/master/packages/cra-template/template/README.md
serviceWorker.unregister()
