import cubejs from '@cubejs-client/core'
import jwt from 'jsonwebtoken'

const CUBE_API_SECRET =
  '4e43c67b4a56f15da413803f27520f4673bf211ead67bccce6b7177c6d558d472a8f8cf949c0806d746b07b7d2324380adbe2042cf10181e3c548fd909a0e0d9'

export const cubejsToken = (tenantId: string) =>
  jwt.sign(
    { tenant_id: tenantId },
    process.env.REACT_APP_CUBEJS_KEY
      ? process.env.REACT_APP_CUBEJS_KEY
      : CUBE_API_SECRET,
    {
      expiresIn: '30d',
    }
  )

export const cubejsApiDev = (tenantId: string) =>
  cubejs(cubejsToken(tenantId), {
    apiUrl: 'https://api2.rovitracker.cloud/reporting/api/v1',
  })

export const cubejsApiLocal = (tenantId: string) =>
  cubejs(cubejsToken(tenantId), {
    apiUrl: 'http://localhost:4000/reporting/api/v1',
  })

export default cubejsApiDev
